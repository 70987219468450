import {ReactElement, ReactFragment, ReactPortal} from 'react'
import {createPortal} from 'react-dom'

const FlashMessagePortal = ({ children }: { children: ReactElement | string | number | ReactFragment | ReactPortal | boolean | null | undefined }) => {
  const portalRoot = document.getElementById('flash-message-portal')

  if (!portalRoot) {
    throw new Error('Portal root element not found')
  }

  return createPortal(children, portalRoot)
}

export default FlashMessagePortal
