import React from 'react'

type PublicProps = {
  children: React.ReactNode
}

export default function Public({ children }: PublicProps): JSX.Element {
  return (
    <main className="w-full page-container" id="flash-message-portal">
      {children}
    </main>
  )
}
