import React, { ReactElement } from 'react'
import usePageTitle from '../../stores/pageTitle'
import Loading from '../Loading'
import useCheckInvitation from '../../libs/checkInvitation'

type PrivateProps = {
  children: ReactElement
}

export default function Private({ children }: PrivateProps): JSX.Element {
  const { pageTitle } = usePageTitle('', true)
  const { isLoading } = useCheckInvitation()
  if (isLoading) {
    return <Loading />
  }

  const title = pageTitle !== '' && (
    <div className="shadow p-4 bg-white">
      <h1 className="font-bold">{pageTitle}</h1>
    </div>
  )

  return (
    <main className="page-container" id="flash-message-portal">
      {title}
      <div>
        {children}
      </div>
    </main>
  )
}
