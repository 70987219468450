import axios from 'axios'
import { useState } from 'react'

export type HttpResponse<T, K = any> = {
  data: T
  meta?: K
}

export type Errors = { [key: string]: string[] }

export default function useHttpClient() {
  const [errors, setErrors] = useState<Errors>({})
  const [isLoading, setIsLoading] = useState(false)

  const client = axios.create({
    withCredentials: true,
    baseURL:
      process.env.NEXT_PUBLIC_API_URL +
      '/api/' +
      process.env.NEXT_PUBLIC_API_VERSION,
  })


  // リクエストが投げられた時にローディングを true に変更
  client.interceptors.request.use((request) => {
    // requestしようとしているURLが正しいサーバーの場合のみXSRFトークンを付与する
    if (process.env.NEXT_PUBLIC_API_URL && (request.baseURL as string).includes(process.env.NEXT_PUBLIC_API_URL)) {
      request.withXSRFToken = true
    }
    setIsLoading(true)
    return request
  })

  // レスポンスを受け取った時にローディングを false に変更
  client.interceptors.response.use(
    (response) => {
      setIsLoading(false)
      return response
    },
    (error) => {
      if (error.response?.data?.errors) {
        setErrors(error.response.data.errors)
      } else if (error.response?.data?.messages) {
        setErrors(error.response.data.messages)
      }
      setIsLoading(false)
      return Promise.reject(error)
    }
  )

  return {
    isLoading,
    client,
    errors,
    setErrors,
  }
}
