import useHttpClient from '../../libs/httpClient'
import { AxiosResponse } from 'axios'

export default function useSigninLogout() {
  const { client, errors } = useHttpClient()
  const request = (): Promise<AxiosResponse> => {
    return client.post('/cleaner/signin/logout')
  }

  return {
    request,
    errors,
  }
}
