import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useSigninLogout from '../../api/signin/logout'
import { useLoginUser } from '../../stores/loginUser'
import { useCleaningTableMode } from '../../stores/cleaningTableMode'
import {FaBars, FaCheckCircle, FaList, FaSearch, FaSync, FaTh} from 'react-icons/fa'

export default function Header(): JSX.Element {
  const [menuOpen, setMenuOpen] = useState(false)
  const { cleaningTableMode, cleaningTableModeReducer } = useCleaningTableMode()
  const { request: logoutRequest } = useSigninLogout()
  const { loginUser, isLogin, mutate } = useLoginUser()
  const isChargePerson = loginUser && loginUser.role === 'charge_person'
  const { pathname } = useRouter()
  const isCleaningTableView = pathname.includes('/cleaning_table')
  const searchClassName = cleaningTableMode.search
    ? 'text-blue-500 ml-4'
    : 'ml-4'
  const multipleSelectClassName = cleaningTableMode.multipleSelect
    ? 'text-blue-500 ml-4'
    : 'ml-4'
  const gridClassName = cleaningTableMode.grid ? 'text-blue-500 ml-4' : 'ml-4'
  const handleSearchClick = () => cleaningTableModeReducer('search')
  const handleMultipleSelectClick = () =>
    cleaningTableModeReducer('multipleSelect')
  const handleClickChangeView = () => cleaningTableModeReducer('grid')
  const handleReload = () => {
    window.location.reload()
  }

  const handleLogout = async () => {
    await logoutRequest()
    await mutate()
  }

  useEffect(() => {
    return () => {
      setMenuOpen(false)
    }
  }, [isLogin, pathname])

  const Cleaners = (
    <li className="nav-item">
      <Link
        href="/cleaners"
        className="px-3 py-3 flex items-center text-sm uppercase font-bold leading-snug text-white hover:opacity-75"
      >
        清掃員一覧
      </Link>
    </li>
  )

  const Logout = isLogin && loginUser && (
    <li className="nav-item">
      <button
        className="px-3 py-3 flex items-center text-sm uppercase font-bold leading-snug text-white hover:opacity-75"
        onClick={handleLogout}
      >
        ログアウト
        <span className="font-normal">
          ({loginUser.lastName + loginUser.firstName}としてログイン中)
        </span>
      </button>
    </li>
  )

  const cleaningTable = (
    <li className="nav-item">
      <Link
        href="/cleaning_table"
        className="px-3 py-3 flex items-center text-sm uppercase font-bold leading-snug text-white hover:opacity-75"
      >
        清掃表
      </Link>
    </li>
  )

  const timeRentCleaning = (
    <li className="nav-item">
      <Link
        href="/time_rent_cleaning_table"
        className="px-3 py-3 flex items-center text-sm uppercase font-bold leading-snug text-white hover:opacity-75"
      >
        時間貸し清掃表
      </Link>
    </li>
  )

  const cleaningEdit = (
    <li className="nav-item">
      <Link
        href="/cleaning_company/edit"
        className="px-3 py-3 flex items-center text-sm uppercase font-bold leading-snug text-white hover:opacity-75"
      >
        会社情報編集
      </Link>
    </li>
  )

  const transportRequestLink = isLogin &&
    loginUser &&
    loginUser.cleaningCompany.transportRequestLink && (
      <li className="nav-item">
        <a
          href={loginUser.cleaningCompany.transportRequestLink}
          target="_blank"
          rel="noreferrer"
          className="px-3 py-3 flex items-center text-sm uppercase font-bold leading-snug text-white hover:opacity-75"
        >
          運搬依頼
        </a>
      </li>
    )

  const reportLostItemLink = loginUser &&
    loginUser.cleaningCompany.reportLostItemLink && (
      <li className="nav-item">
        <a
          href={loginUser.cleaningCompany.reportLostItemLink}
          target="_blank"
          rel="noreferrer"
          className="px-3 py-3 flex items-center text-sm uppercase font-bold leading-snug text-white hover:opacity-75"
        >
          忘れ物報告
        </a>
      </li>
    )

  const displayIcon = cleaningTableMode.grid ? (
    <FaList className="mx-auto" size="16" />
  ) : (
    <FaTh className="mx-auto" size="16" />
  )

  return (
    <header className="fixed w-full z-10 top-0">
      <nav className="w-full relative px-2 py-3 bg-original-gray container mx-auto">
        <div className="w-full flex justify-between items-center md:w-auto px-2 md:static md:block md:justify-start">
          <button
            className="text-white cursor-pointer text-xl leading-none py-1 border border-solid border-transparent rounded bg-transparent block md:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <FaBars />
          </button>
          {!isCleaningTableView && (
            <Link
              href="/"
              className="text-sm font-bold leading-relaxed inline-block whitespace-nowrap uppercase text-white"
            >
              清掃管理システム
            </Link>
          )}
          {isCleaningTableView && (
            <div className="text-white text-center">
              <button className="text-[10px] " onClick={handleReload}>
                リロード
                <FaSync className="mx-auto" size="16" />
              </button>
              <button
                className={gridClassName}
                onClick={handleClickChangeView}
              >
                <div className="text-[10px]">表示</div>
                {displayIcon}
              </button>
              <button className={searchClassName} onClick={handleSearchClick}>
                <div className="text-[10px]">検索</div>
                <FaSearch className="mx-auto" size="16" />
              </button>
              {!cleaningTableMode.grid && (
                <button
                  className={multipleSelectClassName}
                  onClick={handleMultipleSelectClick}
                >
                  <div className="text-[10px]">清掃完了</div>
                  <FaCheckCircle className="mx-auto" size="16" />
                </button>
              )}
            </div>
          )}
        </div>
        <div
          className={
            'md:flex flex-grow items-center' +
            (menuOpen ? ' flex' : ' hidden')
          }
          id="example-navbar-info"
        >
          <ul className="flex flex-col md:flex-row list-none md:ml-auto">
            {isLogin && isChargePerson && cleaningEdit}
            {isLogin && isChargePerson && Cleaners}
            {isLogin && cleaningTable}
            {isLogin && timeRentCleaning}
            {isLogin && transportRequestLink}
            {isLogin && reportLostItemLink}
            {Logout}
          </ul>
        </div>
      </nav>
    </header>
  )
}
