import React, { ReactElement } from 'react'
import Header from './Header'
import Private from './Private'
import Public from './Public'
import { useLoginUser } from '../../stores/loginUser'

type LayoutProps = {
  children: ReactElement
}

export default function Layout({ children }: LayoutProps): JSX.Element {
  const { isLogin } = useLoginUser()
  const privateView = <Private>{children}</Private>
  const publicView = <Public>{children}</Public>
  const loadedView = isLogin ? privateView : publicView

  return (
    <div className="h-screen">
      <Header />
      {loadedView}
    </div>
  )
}
