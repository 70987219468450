import useHttpClient from './httpClient'
import { RawAxiosRequestConfig } from 'axios'

export default function useFetcher<T>() {
  const { client } = useHttpClient()
  return (url: string, config?: RawAxiosRequestConfig): Promise<T> => {
    // これで良いかちょっと微妙...
    return client.get(url, config).then((r) => r.data as T)
  }
}
